import React, {Fragment, useState} from 'react';
import classNames from 'classnames';
import {
  frontend, useShareUrl, useLocale, useEntryTranslations
} from 'pageflow-scrolled/frontend';

import logo from '../images/logo.svg';

import styles from './navigation.module.css';

import {inEditor} from '../inEditor';
import {translations} from '../translations';

const linkProps = inEditor() ?
                  {target: '_blank', rel: 'noopener noreferrer'} :
                  {};

frontend.widgetTypes.register('websiteNavigation', {
  component: function Navigation() {
    const locale = useLocale();
    const t = translations[locale === 'de' ? 'de' : 'en'];

    const currentPage = useCurrentPage(locale);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
      <div className={classNames(styles.navigation,
                                 {[styles.mobileMenuOpen]: mobileMenuOpen})}>
        <div className={styles.toggleLocale}>
          <LanguageSwitch />
        </div>
        <div className={styles.wrapper}>
          <a href={t.start.url} {...linkProps}>
            <img className={styles.logo}
                 src={logo}
                 alt="Pageflow" />
          </a>

          <button className={styles.mobileMenuButton}
                  title={t.toggleMenu}
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen) } />

          <ul className={styles.list}>
            <LinkItem href={t.start.url} active={currentPage === 'start'}>
              {t.start.label}
            </LinkItem>
            <LinkItem href={t.blog.url} active={currentPage === 'blog'}>
              {t.blog.label}
            </LinkItem>
            <LinkItem href={t.pricing.url} active={currentPage === 'pricing'}>
              {t.pricing.label}
            </LinkItem>
          </ul>

          <ul className={styles.list}>
            <LinkItem className={styles.login}
                      analyticsEvent="Login: Click"
                      analyticsId="Navigation"
                      href={t.login.url}>{t.login.label}</LinkItem>
            <LinkItem className={styles.register}
                      analyticsEvent="Register: Click"
                      analyticsId="Navigation"
                      href={t.register.url}>{t.register.label}</LinkItem>
          </ul>
        </div>
      </div>
    );
  }
});

function LanguageSwitch() {
  const currentLocale = useLocale();
  let translations = useEntryTranslations();

  if (!translations.length) {
    translations = [
      {locale: 'de', url: 'https://www.pageflow.io/de/'},
      {locale: 'en', url: 'https://www.pageflow.io/en/'}
    ]
  }

  return translations.map(({url, locale}, index) => {
    let item;

    if (locale === currentLocale) {
      item = (
        <span aria-current="page" className={styles.locale}>
          {locale.toUpperCase()}
        </span>
      );
    }
    else {
      item = (
        <a className={classNames(styles.locale, styles.localeLink)}
           data-event="Toggle Locale: Click"
           data-event-props={JSON.stringify({locale})}
           href={url}
           {...linkProps}>
          {locale.toUpperCase()}
        </a>
      );
    }

    return (
      <Fragment key={locale}>
        {item}
        {index < translations.length - 1 && <span className={styles.localeDivider}>/</span>}
      </Fragment>
    );
  });
}

function LinkItem({active, href, className, analyticsEvent, analyticsId, children}) {
  return (
    <li>
      <a href={href}
         data-event={analyticsEvent}
         data-event-props={analyticsId && JSON.stringify({id: analyticsId})}
         className={classNames(styles.link, {[styles.activeLink]: active}, className)}
         {...linkProps}>
        {children}
      </a>
    </li>
  );
}

function useCurrentPage(locale) {
  const shareUrl = useShareUrl();

  const pages = Object.keys(translations[locale]).filter(key =>
    translations[locale][key].url
  );

  return pages.find(key => {
    const suffix = translations[locale][key].url;
    return shareUrl.match(new RegExp(`${suffix}$`));
  });
}
